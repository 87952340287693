import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next.use(LanguageDetector).init({
  resources: {
    en: {
      translation: {
        siteMetadata: {
          description:
            "A simple plataform to help you administrate and organize your Tongue Tie tests"
        },
        home: { label: "Home", link: "/" },
        about: { label: "About", link: "/about" },
        contact: { label: "Contact", link: "/contact" },
        faq: { label: "FAQ", link: "/faq" },
        admin: { label: "Admin", link: "/admin" },
        privacy: {
          label: "Privacy Policies",
          link: "/privacy-policies"
        },
        terms: { label: "Terms and Conditions", link: "/terms" },
        signin: {
          label: "Sign In",
          link: "https://app.tonguetieapp.com/signin"
        },
        signup: {
          label: "Sign Up",
          link: "https://app.tonguetieapp.com/signup"
        },
        contactPage: {
          title: "Contact",
          name: "Your name",
          email: "Email",
          message: "Message",
          send: "Send"
        },
        thanks: {
          title: "Thank you!",
          description:
            "Your message was sent. We'll contact you as soon as possible."
        },
        notFound: {
          title: "404",
          description: "Ooooops... Page not found!"
        }
      }
    },
    pt: {
      translation: {
        siteMetadata: {
          description:
            "Plataforma simples para te ajudar a administrar e organizar os seus exames do teste da linguinha"
        },
        home: { label: "Página inicial", link: "/pt" },
        about: { label: "Sobre", link: "/pt/about" },
        contact: { label: "Contato", link: "/pt/contact" },
        faq: { label: "Perguntas Frequentes", link: "/pt/faq" },
        admin: { label: "Admin", link: "/admin" },
        privacy: {
          label: "Políticas de privacidade",
          link: "/pt/privacy-policies"
        },
        terms: { label: "Termos e condições", link: "/pt/terms" },
        signin: {
          label: "Entrar",
          link: "https://app.tonguetieapp.com/signin"
        },
        signup: {
          label: "Registrar-se",
          link: "https://app.tonguetieapp.com/signup"
        },
        contactPage: {
          title: "Contato",
          name: "Seu nome",
          email: "Email",
          message: "Mensagem",
          send: "Enviar"
        },
        thanks: {
          title: "Obrigado!",
          description:
            "Sua mensagem foi enviada. Entraremos em contato o mais rápido o possivel."
        },
        notFound: {
          title: "404",
          description: "Ooooops... Página não encontrada!"
        }
      }
    }
  }
});

export default i18next;
