import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import { Reset } from "./Reset";

import i18n from "../i18next";

import "bulma/css/bulma.min.css";

const TemplateWrapper = ({ children, language }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Reset />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={i18n.t(description)} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <meta name="theme-color" content="#fff" />

        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.png`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navbar language={language} />
      <div>{children}</div>
      <Footer language={language} />
    </div>
  );
};

export default TemplateWrapper;
