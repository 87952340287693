import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import logo from "../img/logo.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
// import twitter from "../img/social/twitter.svg";
// import vimeo from "../img/social/vimeo.svg";

import i18next from "../i18next";
import styled from "styled-components";

const Container = styled.footer`
  padding: 3rem 0rem 0rem;
  background-color: transparent;
`;

const MenuList = styled.ul`
  list-style: none !important;
  text-align: left;

  a {
    color: #f5f5f5;
  }
`;

const Social = styled.div`
  padding: 2em;
  a {
    padding: 0.5em 0.5em 0.3em 0.5em;
    border-radius: 1em;
    background-color: white;
    margin: 0.5em;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    display: inline;
  }
`;

const Footer = ({ language: lng }) => (
  <Container className="has-background-dark">
    <div className="content has-text-centered">
      <img
        src={logo}
        alt="Tongue Tie"
        style={{ width: "14em", height: "10em" }}
      />
    </div>
    <div className="content has-text-centered">
      <div className="container">
        <div className="columns">
          <div className="column is-4">
            <section className="menu">
              <MenuList className="menu-list">
                <li>
                  <Link to={i18next.t("home.link", { lng })}>
                    {i18next.t("home.label", { lng })}
                  </Link>
                </li>
                <li>
                  <a href={i18next.t("signin.link", { lng })}>
                    {i18next.t("signin.label", { lng })}
                  </a>
                </li>
                <li>
                  <a href={i18next.t("signup.link", { lng })}>
                    {i18next.t("signup.label", { lng })}
                  </a>
                </li>
                <li>
                  <a
                    href={i18next.t("admin.link", { lng })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i18next.t("admin.label", { lng })}
                  </a>
                </li>
              </MenuList>
            </section>
          </div>
          <div className="column is-4">
            <section>
              <MenuList className="menu-list">
                <li>
                  <Link to={i18next.t("contact.link", { lng })}>
                    {i18next.t("contact.label", { lng })}
                  </Link>
                </li>
                <li>
                  <Link to={i18next.t("faq.link", { lng })}>
                    {i18next.t("faq.label", { lng })}
                  </Link>
                </li>
                <li>
                  <Link to={i18next.t("privacy.link", { lng })}>
                    {i18next.t("privacy.label", { lng })}
                  </Link>
                </li>
                <li>
                  <Link to={i18next.t("terms.link", { lng })}>
                    {i18next.t("terms.label", { lng })}
                  </Link>
                </li>
              </MenuList>
            </section>
          </div>
          <Social className="column is-4">
            <a
              title="facebook"
              href="https://facebook.com/tonguetieapp-100439735062049"
            >
              <img
                src={facebook}
                alt="Facebook"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            {/* <a title="twitter" href="https://twitter.com">
              <img
                className="fas fa-lg"
                src={twitter}
                alt="Twitter"
                style={{ width: "1em", height: "1em" }}
              />
            </a> */}
            <a title="instagram" href="https://instagram.com/tonguetieapp">
              <img
                src={instagram}
                alt="Instagram"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            {/* <a title="vimeo" href="https://vimeo.com">
              <img
                src={vimeo}
                alt="Vimeo"
                style={{ width: "1em", height: "1em" }}
              />
            </a> */}
          </Social>
        </div>
      </div>
    </div>
  </Container>
);

Footer.propTypes = {
  children: PropTypes.node,
  language: PropTypes.string.isRequired,
};

Footer.defaultProps = {
  children: null,
};

export default Footer;
