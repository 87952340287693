import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import styled from "styled-components";

import logo from "../img/logo.png";
import i18next from "../i18next";
import { Language } from "./Language";

const Brand = styled.div`
  font-size: 1.3rem;
  font-family: "Pacifico", cursive;
`;

class Navbar extends Component {
  state = {
    active: false,
    navBarActiveClass: "",
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.toggleHamburger();
    }
  };

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    const { language: lng } = this.props;

    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link
              to={i18next.t("home.link", { lng })}
              className="navbar-item"
              title="Logo"
            >
              <img src={logo} alt="Tongue Tie app" style={{ width: "37px" }} />
              <Brand style={{ paddingLeft: "8px" }}>Tongue Tie app</Brand>
            </Link>
            {/* Hamburger menu */}
            <div
              role="button"
              tabIndex="0"
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={this.toggleHamburger}
              onKeyDown={this.handleKeyDown}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link
                className="navbar-item"
                to={i18next.t("home.link", { lng })}
              >
                {i18next.t("home.label", { lng })}
              </Link>
              <Link
                className="navbar-item"
                to={i18next.t("contact.link", { lng })}
              >
                {i18next.t("contact.label", { lng })}
              </Link>
              <a
                className="navbar-item"
                href={i18next.t("signin.link", { lng })}
              >
                {i18next.t("signin.label", { lng })}
              </a>
              <a
                className="navbar-item"
                href={i18next.t("signup.link", { lng })}
              >
                {i18next.t("signup.label", { lng })}
              </a>
              <div
                className="navbar-item"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Language />
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  language: PropTypes.string.isRequired,
};

export default Navbar;
