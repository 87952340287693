import { createGlobalStyle } from "styled-components";

export const Reset = createGlobalStyle` 
  h1,h2,h3,h4,h5 {
    font-weight: lighter;
  }

  .content h1,.content h2,.content h3,.content h4,.content h5 {
    font-weight: lighter;
  }

  body {
    font-weight: lighter; 
  }
`;
