import React from "react";
import i18next from "../i18next";
import { Link } from "gatsby";

import BR from "../img/br.svg";
import US from "../img/us.svg";

export const Language = () => {
    const changeLanguage = (lng) => {
      i18next.changeLanguage(lng);
    };

    return (
    <>
      <Link
        onClick={() => changeLanguage('pt')}
        to={i18next.t("home.link", { lng: "pt" })}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img
          src={BR}
          alt="Português"
          style={{ width: 24, height: 16, marginRight: 8 }}
        />
      </Link>
      <Link
        onClick={() => changeLanguage('en')}
        to={i18next.t("home.link", { lng: "en" })}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img src={US} alt="English" style={{ width: 24, height: 16 }} />
      </Link>
    </>
  );
};
